/*!
 * Bootstrap 4 multi dropdown navbar ( https://bootstrapthemes.co/demo/resource/bootstrap-4-multi-dropdown-navbar/ )
 * Copyright 2017.
 * Licensed under the GPL license
 */

// $(document).ready(function () {
// $(window).on("load", function () {
// console.log("here!!", $(".dropdown-menu a.dropdown-toggle").length);
setTimeout(() => {
  //   console.log("here2", $(".dropdown-menu a.dropdown-toggle").length);
  // $(".dropdown-menu a.dropdown-toggle").on("click", function (e) {
  //   var $el = $(this);
  //   $el.toggleClass("active-dropdown");
  //   var $parent = $(this).offsetParent(".dropdown-menu");
  //   if (!$(this).next().hasClass("show")) {
  //     $(this)
  //       .parents(".dropdown-menu")
  //       .first()
  //       .find(".show")
  //       .removeClass("show");
  //   }
  //   var $subMenu = $(this).next(".dropdown-menu");
  //   $subMenu.toggleClass("show");
  //   $(this).parent("li").toggleClass("show");
  //   $(this)
  //     .parents("li.nav-item.dropdown.show")
  //     .on("hidden.bs.dropdown", function (e) {
  //       $(".dropdown-menu .show").addClass("show01");
  //       $(".dropdown-menu .show").removeClass("show");
  //       $el.removeClass("active-dropdown");
  //     });
  //   if (!$parent.parent().hasClass("navbar-nav")) {
  //     $el.next().css({ top: $el[0].offsetTop, left: $parent.outerWidth() - 4 });
  //   }
  //   return false;
  // });
}, 3000);
// });
